import React, { useState, useRef } from "react";
import { useAddNewWardMutation, useGetCommunitiesListQuery } from './wardSlice'
const AddWardOffCanavas = () => {

    const [addNewCommunity, { isLoading }] = useAddNewWardMutation()
    const [addWard, setAddWard] = useState({ ward_number: "", community_id: "" });
    const refClose = useRef(null);

    const { data: communities } = useGetCommunitiesListQuery('getCommunitiesList');

    const handleChange = (e) => setAddWard({ ...addWard, [e.target.name]: e.target.value });

    const handleClick = async (e) => {
        e.preventDefault();
        try {
            await addNewCommunity({ community_id: addWard.community_id, ward_number: addWard.ward_number }).unwrap()
            setAddWard({ ward_number: "", community_id: "" })
            refClose.current.click()
        }
        catch (e) {
            console.error(e)
        }
    }
    return (
        <>
            <button type="button" className="Add_button bg-left-navabr  text-light  btn btn-secondary" data-bs-toggle="offcanvas" data-bs-target="#depOffCanvas" aria-controls="depOffCanvas"><i className="fa fa-plus" aria-hidden="true"></i> Add New Ward</button>

            <div className="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="1" id="depOffCanvas" aria-labelledby="depOffCanvas">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvas1Label">Add Ward</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ref={refClose}></button>
                </div>
                <div className="offcanvas-body">
                    <div>
                        <form className="row g-3">
                            <div className="col-md-12 text-left">
                                <label htmlFor="ward_number" className="form-label sfont-weight-bold">
                                    Ward Number
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="ward_number"
                                    name="ward_number"
                                    value={addWard.ward_number}
                                    onChange={handleChange}
                                    placeholder="Ex: 123"
                                    autoComplete="off"
                                    required
                                />
                            </div>
                            <div className="col-md-12 text-left">
                                <label htmlFor="district_id" className="form-label font-weight-bold">
                                    Community Name
                                </label>
                                <select className="form-select" id="community_id" name="community_id" value={addWard.community_id} onChange={handleChange} required>
                                    <option value="" defaultValue>
                                        Select Community
                                    </option>
                                    {communities?.entities && Object.keys(communities.entities).map(item =>
                                        <option key={item} value={item}>{communities.entities[item].community_name}</option>
                                    )}
                                </select>
                            </div>
                            <div className="col-md-12">
                                <button type="submit" className="btn btn-ff9b44 btn-block btn-round"
                                    onClick={handleClick}
                                    disabled={!(addWard.ward_number && addWard.community_id)}>
                                    {isLoading ? <i className="fa fa-spinner fa-spin"></i> : "Submit"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddWardOffCanavas;