import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useEditWardMutation, useGetWardsQuery } from "./wardSlice";

const WardExcerpt = ({ wardId, index, setWardData }) => {
    const { ward } = useGetWardsQuery('getWards', {
        selectFromResult: ({ data }) => ({
            ward: data?.entities[wardId],
        }),
    });

    const [editWard] = useEditWardMutation()
    const navigate = useNavigate();
    const [surveyState, setSurveyState] = useState('')
    const handleGeofence = () => {
        setWardData({ wardId: wardId, wardNumber: ward?.ward_number, wardCommunity: ward?.community_name })
        navigate('/b2g/geofanceTable');
    };
    const handleInc = () => {
        navigate(`/b2g/WardsInc/${wardId}`);
    };
    const handleHelper = () => {
        // setWardData({ wardId: wardId, wardNumber: ward?.ward_number, wardCommunity: ward?.community_name })
        navigate(`/b2g/HelperWards/${wardId}`);
    };
    const handleDriver = () => {
        setWardData({ wardId: wardId, wardNumber: ward?.ward_number, wardCommunity: ward?.community_name })
        navigate('/b2g/DriverWards');
    };
    const handleVehicle = () => {
        // setWardData({ wardId: wardId, wardNumber: ward?.ward_number, wardCommunity: ward?.community_name })
        navigate(`/b2g/VehicleWards/${wardId}`);
    };

    const handleClick = async (e) => {
        e.preventDefault()
        try {
            await editWard({ 'ward_id': wardId, body: { is_survey: ward?.is_survey === 1 ? '0' : '1' } })
        } catch (e) {
            console.error(e)
        }
    }
    useEffect(() => {
        setSurveyState(ward?.is_survey)
    }, [ward])
    return (
        <>
            <tr key={index} className="listData search_button text-left fw-normal">
                <td>{index + 1}</td>
                <td>{ward?.ward_number}</td>
                <td>{ward?.community_name}</td>
                {/* <td>{ward?.is_survey === '1' ? 'On' : 'Off'}</td> */}
                <td>
                    <div className="form-check form-switch">
                        <input className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                            checked={surveyState}
                            onClick={handleClick}
                        />
                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked"></label>
                    </div>
                </td>
                <td>
                    <div className="dropdown">
                        <button
                            className="btn dropdown-toggle p-2"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{ border: 'none' }}
                        >
                            <i className="fas fa-ellipsis-v" style={{ color: 'gray' }}></i>
                        </button>
                        <ul className="dropdown_Box dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>
                                <div
                                    className="dropdown-item"
                                    onClick={handleGeofence}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Geofence
                                </div>
                                <div
                                    className="dropdown-item"
                                    onClick={handleInc}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Inc
                                </div>
                                <div
                                    className="dropdown-item"
                                    onClick={handleHelper}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Helper
                                </div>
                                <div
                                    className="dropdown-item"
                                    onClick={handleDriver}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Driver
                                </div>
                            </li>
                        </ul>
                    </div>
                </td>
            </tr>
            <div className="Card_table_data">
                <div div className="Card_data">
                    <span className="Card_srNo">{index + 1}</span>
                </div>
                <div className="Card">
                    <div className="Card_data">
                        <span className="Card_srNo">Ward Number :-</span>
                        <span className="Card_data_list">{ward?.ward_number}</span>
                    </div>
                    <div className="Card_data">
                        <span className="Card_srNo">Community Name :-</span>
                        <span className="Card_data_list">{ward?.community_name}</span>
                    </div>

                </div>
                <div className="Card">
                    <div className="Card_data">
                        <span className="Card_srNo">Survey Status :-</span>
                        <span className="Card_data_list">
                            <div className="form-check form-switch">
                                <input className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckChecked"
                                    checked={surveyState}
                                    onClick={handleClick}
                                />
                                <label className="form-check-label" htmlFor="flexSwitchCheckChecked"></label>
                            </div>
                        </span>
                    </div>
                    <div className="dropdown">
                        <span className="Card_srNo">Action</span>
                        <button
                            className="btn dropdown-toggle p-2"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{ border: 'none' }}
                        >
                            <i className="fas fa-ellipsis-v" style={{ color: 'gray' }}></i>
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>
                                <div
                                    className="dropdown-item"
                                    onClick={handleGeofence}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Geofence
                                </div>
                                <div
                                    className="dropdown-item"
                                    onClick={handleInc}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Inc
                                </div>
                                <div
                                    className="dropdown-item"
                                    onClick={handleHelper}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Helper
                                </div>
                                <div
                                    className="dropdown-item"
                                    onClick={handleDriver}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Driver
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </>
    );
};

export default WardExcerpt;
