import { useGethelperQuery, useGetvehicleQuery } from "./wardSlice";
import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
// import HelperWardExcerpt from "./HelperWardExcerpt";
import EditHelperWard from "./EditHelperWard"
import AddHelper from "./addHelper";
// import VehicleWardExcerpt from "./vehicleWardExcerpt";

const VehicleWard = () => {
    const { id } = useParams;
    // const [editHelper, setEditHelper] = useState({})
    // const refOpen = useRef();

    const {
        data: vehicle,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetvehicleQuery(id);

    // const editCommunity = (HelperId) => {
    //     refOpen.current.click();
    //     const data = Helper?.entities[HelperId]
    //     setEditHelper({ helper_id: data?.helper_id, helper_name: data?.helper_name, h_leave: data?.h_leave, h_blocked: data?.h_blocked, h_ward_id: data?.h_ward_id })
    // }

    return (
        <>
            <div className="container-fluid mt-5">
                <div className="text-right">
                    {/* <AddHelper /> */}
                </div>
                <div className="card card-dark mt-4">
                    <div className="table-border-style">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead className="search_button">
                                    <tr className=" text-left">
                                        <th scope="col">Sr. No.</th>
                                        <th scope="col">helper Name</th>
                                        <th scope="col">Number</th>
                                        <th scope="col">Leave</th>
                                        <th scope="col">Blocked</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* {
                                        vehicle?.ids?.map((Id, index) => {
                                            return <VehicleWardExcerpt editCommunity={editCommunity} vehicle={vehicle?.entities[Id]} index={index} />
                                        })
                                    } */}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {/* <EditHelperWard refOpen={refOpen} editHelper={editHelper} setEditHelper={setEditHelper} /> */}

        </>
    )
}

export default VehicleWard