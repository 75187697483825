import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const urlMain = process.env.REACT_APP_URL_MAIN;
const urlMainB2g = process.env.REACT_APP_URL_B2G;

const initialState = {
    panelType: ['b2b', 'b2g'].includes(localStorage.getItem('panelType')) ? localStorage.getItem('panelType') : 'b2b',
    adminRole: parseInt(localStorage.getItem('adminRole')),
    token: localStorage.getItem('tokenAdminPanel'),
    isAuthenticated: null,
    loginError: null,
    loginStatus: null,
    // B2G wards search data 
    searchdata: {},
    // B2G survey search Data
    searchDataSurvey: {},
    searchDatacollection: {},
};

export const login = createAsyncThunk(
    'auth/login',
    async (credentials, { rejectWithValue }) => {
        try {
            const response = await fetch(`${urlMain}/login`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(credentials),
            });
            const data = await response.json();
            if (!response.ok || response.status === 203) {
                throw new Error(data.message || 'Login failed');
            }
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const loginB2g = createAsyncThunk(
    'auth/loginB2g',
    async (credentials, { rejectWithValue }) => {
        try {
            const response = await fetch(`${urlMainB2g}/authentication`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(credentials),
            });

            const data = await response.json();
            if (!response.ok || response.status === 203) {
                throw new Error(data.message || 'Login failed');
            }
            return data.data[0];
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const verifyToken = createAsyncThunk(
    'auth/verifyToken',
    async (token, { rejectWithValue }) => {
        try {
            const response = await fetch(`${urlMain}/verifyToken`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error('Token verification failed');
            }
            return data;

        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout(state) {
            state.adminRole = null;
            if (state.panelType === 'b2b') localStorage.removeItem('adminRole');
            state.token = null;
            localStorage.removeItem('tokenAdminPanel');
            state.isAuthenticated = false;
            state.loginError = null;
            state.loginStatus = null;
        },
        panelChange(state, action) {
            state.panelType = action.payload.panelType;
            localStorage.setItem('panelType', action.payload.panelType);
            state.token = null;
            state.adminRole = null;
            localStorage.removeItem('adminRole');
            localStorage.removeItem('tokenAdminPanel');
            state.loginError = null;
            state.loginStatus = null;
        },
        tokenNotFound(state, action) {
            state.isAuthenticated = false;
        },
        searchWarde(state, action) {
            state.searchdata = action.payload;
        },
        searchSurvey(state, action) {
            state.searchDataSurvey = action.payload;
        },
        searchcollection(state, action) {
            state.searchDatacollection = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.loginStatus = 'pending';
                state.loginError = null;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.token = action.payload.data.token;
                state.adminRole = parseInt(action.payload.data.roleId);
                localStorage.setItem('adminRole', action.payload.data.roleId);
                localStorage.setItem('tokenAdminPanel', action.payload.data.token);
                state.loginStatus = 'succeeded';
                state.loginError = null;
                state.isAuthenticated = true;
            })
            .addCase(login.rejected, (state, action) => {
                state.loginStatus = 'failed';
                state.loginError = action.payload || 'Login failed';
            })
            .addCase(loginB2g.pending, (state) => {
                state.loginStatus = 'pending';
                state.loginError = null;
            })
            .addCase(loginB2g.fulfilled, (state, action) => {
                state.token = action.payload;
                localStorage.setItem('tokenAdminPanel', action.payload);
                state.loginStatus = 'succeeded';
                state.loginError = null;
                state.isAuthenticated = true;
            })
            .addCase(loginB2g.rejected, (state, action) => { // Corrected to `loginB2g.rejected`
                state.loginStatus = 'failed';
                state.loginError = action.payload || 'Login failed';
            })
            .addCase(verifyToken.pending, (state) => {
                // Optional: handle token verification pending state
            })
            .addCase(verifyToken.fulfilled, (state, action) => {
                state.isAuthenticated = true;
                state.token = localStorage.getItem('tokenAdminPanel');
                if (state.panelType === 'b2b') state.adminRole = parseInt(localStorage.getItem('adminRole'));
            })
            .addCase(verifyToken.rejected, (state) => {
                state.isAuthenticated = false;
            });
    },
});

export const { logout, tokenNotFound, panelChange, searchWarde, searchSurvey, searchcollection } = authSlice.actions;
export default authSlice.reducer;
