import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";



export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({

        addNewcollectionroute: builder.mutation({
            query: (geo_id, assignment_id) => ({
                // url: `/b2g/eco-admin/create-collection-route?geo_id=${geo_id}&assignment_id=${assignment_id}`,
                // method: 'POST',
                // body: body
            }),
            // invalidatesTags: [
            //     { type: 'B2gDriver', id: 'collectionroute' }
            // ]
        }),


    })
})

export const {
    useAddNewcollectionrouteMutation,
} = extendedApiSlice