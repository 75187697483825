import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";

const wardsAdaptor = createEntityAdapter({
    selectId: (entity) => entity.ward_id,
})
export const communitiesAdaptor = createEntityAdapter({
    selectId: (entity) => entity.community_id,
})
export const incAdaptor = createEntityAdapter({
    selectId: (entity) => entity.inc_id,
})
export const helperAdaptor = createEntityAdapter({
    selectId: (entity) => entity.helper_id,
})
export const driverAdaptor = createEntityAdapter({
    selectId: (entity) => entity.p_id,
})
export const vehicleAdaptor = createEntityAdapter({
    selectId: (entity) => entity.vehicle_id,
})

const initialWards = wardsAdaptor.getInitialState()
const initialInc = incAdaptor.getInitialState()
const initialhelper = helperAdaptor.getInitialState()
const initialvehicle = helperAdaptor.getInitialState()
const initialdriver = driverAdaptor.getInitialState()
const initialCommunities = communitiesAdaptor.getInitialState()

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getWards: builder.query({
            query: () => '/b2g/eco-admin/address/community/ward',
            transformResponse: responseData => {
                if (responseData?.status === false) {
                    throw new Error(responseData?.statusCode)
                }
                return wardsAdaptor.setAll(initialWards, responseData?.data)
            },
            providesTags: (result, error, arg) => {
                if (error) {
                    return []
                }
                if (!error && result?.ids) {
                    return [
                        { type: 'Wards', id: "List" },
                        ...result.ids.map(id => ({ type: 'Wards', id }))
                    ]
                }
            }
        }),
        addNewWard: builder.mutation({
            query: body => ({
                url: '/b2g/eco-admin/address/community/ward',
                method: 'POST',
                body: body
            }),
            invalidatesTags: [
                { type: 'Wards', id: 'List' }
            ]
        }),
        editWard: builder.mutation({
            query: payLoad => ({
                url: `/b2g/eco-admin/address/community/ward/${payLoad.ward_id}`,
                method: 'PATCH',
                body: payLoad.body
            }),
            invalidatesTags: [
                { type: 'Wards', id: 'List' }
            ]
        }),
        getCommunitiesList: builder.query({
            query: () => '/b2g/eco-admin/address/community',
            transformResponse: responseData => {
                return communitiesAdaptor.setAll(initialCommunities, responseData?.data)
            },
            providesTags: (result, error, arg) => {
                if (error) {
                    return []
                }
                if (!error && result?.ids) {
                    return [
                        { type: 'Communities', id: "List" },
                        ...result.ids.map(id => ({ type: 'Communities', id }))
                    ];
                }
            }
        }),

        getinc: builder.query({
            query: (wardId) => `/b2g/eco-admin/inc?inc_ward_id=${wardId}`,
            transformResponse: responseData => {
                if (responseData?.status === false) {
                    throw new Error(responseData?.statusCode)
                }
                return incAdaptor.setAll(initialInc, responseData?.data)

            },
            providesTags: (result, error, arg) => {
                if (error) {
                    return []
                }
                if (!error && result?.ids) {
                    return [
                        { type: 'inc', id: "List" },
                        ...result.ids.map(id => ({ type: 'inc', id }))
                    ]
                }
            }
        }),
        editInc: builder.mutation({
            query: payLoad => ({
                url: `/b2g/eco-admin/inc`,
                method: 'PATCH',
                body: payLoad,
            }),
            invalidatesTags: [
                { type: 'inc', id: 'List' }
            ]
        }),
        deleteinc: builder.mutation({
            query: id => ({
                url: `/b2g/eco-admin/inc/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [
                { type: 'inc', id: 'List' }
            ]
        }),
        addNewinc: builder.mutation({
            query: body => ({
                url: '/b2g/eco-admin/inc',
                method: 'POST',
                body: body
            }),
            invalidatesTags: [
                { type: 'inc', id: 'List' }
            ]
        }),

        gethelper: builder.query({
            query: (wardId) => `/b2g/eco-admin/helper`,
            transformResponse: responseData => {
                if (responseData?.status === false) {
                    throw new Error(responseData?.statusCode)
                }
                return helperAdaptor.setAll(initialdriver, responseData?.data)

            },
            providesTags: (result, error, arg) => {
                if (error) {
                    return []
                }
                if (!error && result?.ids) {
                    return [
                        { type: 'helper', id: "List" },
                        ...result.ids.map(id => ({ type: 'helper', id }))
                    ]
                }
            }
        }),
        editHelperWard: builder.mutation({
            query: payLoad => ({
                url: `/b2g/eco-admin/helper`,
                method: 'PATCH',
                body: payLoad,
            }),
            invalidatesTags: [
                { type: 'helper', id: 'List' }
            ]
        }),
        deletehelper: builder.mutation({
            query: id => ({
                url: `/b2g/eco-admin/helper/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [
                { type: 'helper', id: 'List' }
            ]
        }),
        addNewHelper: builder.mutation({
            query: body => ({
                url: '/b2g/eco-admin/helper',
                method: 'POST',
                body: body
            }),
            invalidatesTags: [
                { type: 'helper', id: 'List' }
            ]
        }),

        getdriver: builder.query({
            query: (wardId) => `/b2g/eco-admin/driver`,
            transformResponse: responseData => {
                if (responseData?.status === false) {
                    throw new Error(responseData?.statusCode)
                }
                return driverAdaptor.setAll(initialhelper, responseData?.data)
            },
            providesTags: (result, error, arg) => {
                if (error) {
                    return []
                }
                if (!error && result?.ids) {
                    return [
                        { type: 'driver', id: "List" },
                        ...result.ids.map(id => ({ type: 'driver', id }))
                    ]
                }
            }
        }), addNewDriver: builder.mutation({
            query: body => ({
                url: '/b2g/eco-admin/driver',
                method: 'POST',
                body: body
            }),
            invalidatesTags: [
                { type: 'inc', id: 'List' }
            ]
        }),
        editDriverWard: builder.mutation({
            query: payLoad => ({
                url: `/b2g/eco-admin/driver`,
                method: 'PATCH',
                body: payLoad,
            }),
            invalidatesTags: [
                { type: 'driver', id: 'List' }
            ]
        }),
        deleteDriver: builder.mutation({
            query: id => ({
                url: `/b2g/eco-admin/driver/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [
                { type: 'driver', id: 'List' }
            ]
        }),
        getvehicle: builder.query({
            query: () => `/b2g/eco-admin/vehicle`,
            transformResponse: responseData => {
                if (responseData?.status === false) {
                    throw new Error(responseData?.statusCode)
                }
                return vehicleAdaptor.setAll(initialvehicle, responseData?.data)
            },
            providesTags: (result, error, arg) => {
                if (error) {
                    return []
                }
                if (!error && result?.ids) {
                    return [
                        { type: 'vehicle', id: "List" },
                        ...result.ids.map(id => ({ type: 'vehicle', id }))
                    ]
                }
            }
        }),
    })
})

export const {
    useGetWardsQuery,
    useAddNewWardMutation,
    useEditWardMutation,
    useGetCommunitiesListQuery,
    useGetincQuery,
    useDeleteincMutation,
    useEditIncMutation,
    useAddNewincMutation,
    useGethelperQuery,
    useDeletehelperMutation,
    useEditHelperWardMutation,
    useAddNewHelperMutation,
    useGetdriverQuery,
    useAddNewDriverMutation,
    useEditDriverWardMutation,
    useDeleteDriverMutation,
    useGetvehicleQuery,
} = extendedApiSlice

