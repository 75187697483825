import React, { useState, useRef } from "react";
import { useAddNewDriverMutation } from "./wardSlice";
const AddWardDriver = () => {

    const [addDriver, setAddDriver] = useState({ name: "", number: "", ward_id: "" });
    const refClose = useRef(null);

    const [addNewDriver, { isLoading }] = useAddNewDriverMutation();

    const handleChange = (e) => setAddDriver({ ...addDriver, [e.target.name]: e.target.value });

    const handleClick = async (e) => {
        e.preventDefault();
        try {
            await addNewDriver({ name: addDriver.name, number: addDriver.number, ward_id: addDriver.ward_id }).unwrap()
            setAddDriver({ name: "", number: "", ward_id: "" })
            refClose.current.click()
        }
        catch (e) {
            console.error(e)
        }
    }
    console.log(addDriver, "adddriver");

    return (
        <>
            <button type="button" className="Add_button bg-left-navabr  text-light  btn btn-secondary" data-bs-toggle="offcanvas" data-bs-target="#depOffCanvas" aria-controls="depOffCanvas"><i className="fa fa-plus" aria-hidden="true"></i> Add New Driver</button>
            <div className="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="1" id="depOffCanvas" aria-labelledby="depOffCanvas">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvas1Label">Add Driver</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ref={refClose}></button>
                </div>
                <div className="offcanvas-body">
                    <div>
                        <form className="row g-3">
                            <div className="col-md-12 text-left">
                                <label htmlFor="community_name" className="form-label font-weight-bold">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="community_name"
                                    name="name"
                                    value={addDriver.name}
                                    onChange={handleChange}
                                    placeholder="Name"
                                    autoComplete="off"
                                    required
                                />
                            </div>
                            <div className="col-md-12 text-left">
                                <label htmlFor="community_name" className="form-label font-weight-bold">
                                    Number
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="community_name"
                                    name="number"
                                    value={addDriver.number}
                                    onChange={handleChange}
                                    placeholder="Number"
                                    autoComplete="off"
                                    required
                                />
                            </div>
                            <div className="col-md-12 text-left">
                                <label htmlFor="district_id" className="form-label font-weight-bold">
                                    District Name
                                </label>
                                <select value={addDriver.ward_id} onChange={handleChange} className="form-select" id="district_id" name="ward_id" required>
                                    <option value="" defaultValue>
                                        Select Ward
                                    </option>
                                    <option value={"4cd2"}>50</option>
                                    <option value={"a0f5"}>93</option>
                                </select>
                            </div>

                            <div className="col-md-12">
                                <button type="submit" className="btn btn-ff9b44 btn-block btn-round"
                                    onClick={handleClick}
                                    disabled={!(addDriver.name && addDriver.number && addDriver.ward_id)}
                                >
                                    {isLoading ? <i className="fa fa-spinner fa-spin"></i> : "Submit"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddWardDriver