import { useRef } from "react";
import { useEditDriverWardMutation } from "./wardSlice";


const EditDriverWard = ({ refOpen, editDriver, setEditDriver }) => {

    const [editDriverData, { isLoading }] = useEditDriverWardMutation()

    const handleChange = (e) => {
        setEditDriver({ ...editDriver, [e.target.name]: e.target.value })
    }
    const refClosedriver = useRef(null)

    const handleClick = async (e) => {
        e.preventDefault()
        try {
            await editDriverData({ p_id: editDriver?.p_id, p_name: editDriver?.p_name, is_leave: editDriver?.is_leave, is_blocked: editDriver?.is_blocked, p_deleted: editDriver?.p_deleted, p_ward_id: editDriver?.p_ward_id }).unwrap()
            refClosedriver.current.click();
            setEditDriver({ p_id: "", p_name: "", is_leave: "", is_blocked: "", h_ward_id: "" });
        }
        catch (e) {
            console.log("Error in community edit", e)
        }
    }
    return (
        <>
            <button ref={refOpen} type="button" className="btn d-none" data-bs-toggle="offcanvas" data-bs-target="#depOffCanvas1" aria-controls="depOffCanvas1"></button>

            <div className="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="depOffCanvas1" aria-labelledby="depOffCanvas1">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvas1Label">Edit driver</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ref={refClosedriver}></button>
                </div>
                <div className="offcanvas-body">
                    <div>
                        <form className="row g-3">
                            <div className="col-md-12 text-left">
                                <label htmlFor="ename" className="form-label font-weight-bold">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="community_name"
                                    name="p_name"
                                    value={editDriver?.p_name}
                                    onChange={handleChange}
                                    placeholder="Name"
                                    autoComplete="off"
                                    required
                                />
                            </div>
                            <div className="col-md-12 text-left">
                                <label htmlFor="ename" className="form-label font-weight-bold">
                                    Number
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="community_name"
                                    name="p_number"
                                    value={editDriver?.p_number}
                                    onChange={handleChange}
                                    placeholder="Name"
                                    autoComplete="off"
                                    required
                                />
                            </div>
                            <div className="col-md-12 text-left">
                                <label htmlFor="ename" className="form-label font-weight-bold">
                                    Leave
                                </label>
                                <select className="form-select" value={editDriver?.is_leave}
                                    onChange={handleChange}
                                    id="community_type" name="is_leave" required>
                                    <option value="">--Select--</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                            <div className="col-md-12 text-left">
                                <label htmlFor="ename" className="form-label font-weight-bold">
                                    Blocked
                                </label>
                                <select className="form-select" value={editDriver?.is_blocked}
                                    onChange={handleChange}
                                    id="community_type" name="is_blocked" required>
                                    <option value="">--Select--</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                            <div className="col-md-12 text-left">
                                <label htmlFor="ename" className="form-label font-weight-bold">
                                    Deleted
                                </label>
                                <select className="form-select" value={editDriver?.p_deleted}
                                    onChange={handleChange}
                                    id="community_type" name="p_deleted" required>
                                    <option value="">--Select--</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                            <div className="col-md-12 text-left">
                                <label htmlFor="ename" className="form-label font-weight-bold">
                                    ward id
                                </label>
                                <select className="form-select" value={editDriver?.p_ward_id}
                                    onChange={handleChange}
                                    id="community_type" name="p_ward_id" required>
                                    <option value="">--Select--</option>
                                    <option value="4cd2">50</option>
                                    <option value="a0f5">93</option>
                                </select>
                            </div>

                            <div className="col-md-12">
                                <button type="submit" className="btn btn-ff9b44 btn-block btn-round"
                                    onClick={handleClick}
                                >
                                    {isLoading ? <i className="fa fa-spinner fa-spin"></i> : "Submit"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditDriverWard