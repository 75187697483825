import { useCollectionController } from "./collectionController";
import { useEffect, useState } from "react";
import moment from "moment";
import SpinnerForPage from "../../components/spinner/SpinnerForPage";
import Pagination from "../../utils/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { searchcollection } from "../auth/authSlice";
import { useNavigate } from "react-router-dom";
import { useGetCollectionListQuery, useGetDriversQuery } from "./collectionRtk";
export default function CollectionList() {
    let totalPages = 1;
    let totalCollection = 0;
    const [searchdata, setSearchdata] = useState({})

    const { page,
        setPage,
        collectionList,
        isErrorCollectionList,
        errorCollectionList,
        isLoadingCollectionList,
        handleNavigate,
    } = useCollectionController(searchdata)

    // Driver api
    const {
        data: drivers,
        isLoading,
    } = useGetDriversQuery('getDrivers')

    const dispatch = useDispatch();
    const { searchDatacollection } = useSelector((state) => state.auth);

    const navigate = useNavigate();
    if (collectionList && collectionList?.entities && collectionList?.totalCollections && collectionList?.totalPages) {
        totalPages = collectionList.totalPages
        totalCollection = collectionList.totalCollections
    }
    const searchHandle = (e) => {
        dispatch(searchcollection({ ...searchDatacollection, [e.target.name]: e.target.value }))
    }
    const dataSearch = () => {
        setSearchdata(searchDatacollection)
    }

    useEffect(() => {
        setSearchdata(searchDatacollection);
    }, [navigate]);

    return (
        <div className=" container-fluid p-3">
            <div className="search_button col-11 mt-3" style={{ height: '68vh' }}>
                <div className="search_button w-100 d-flex justify-content-center gap-5">
                    <select className="district_select form-select " value={searchDatacollection?.ward_id} name="ward_id" onChange={searchHandle} >
                        <option value="" >Select Area</option>
                        <option value="4cd2" >50 Jaipur</option>
                        <option value="a0f5" >93 Jaipur</option>
                    </select>
                    <input className="form-select" value={searchDatacollection?.startdate} onChange={searchHandle} name="startdate" type="date" placeholder="Search Number" />
                    <input className="form-select" value={searchDatacollection?.enddate} onChange={searchHandle} name="enddate" type="date" placeholder="Search Number" />
                    <select className="district_select form-select " value={searchDatacollection?.p_id} name="p_id" onChange={searchHandle} >
                        <option value="" >Select Driver Name</option>
                        {!isLoadingCollectionList && !isErrorCollectionList && collectionList?.entities && drivers?.ids.map((item, index) => (
                            <option value={item} >{drivers?.entities[item]?.p_name}</option>
                        ))}
                    </select>
                    <button className="bg-left-navabr  text-light  btn btn-secondary" onClick={dataSearch}>Search</button>
                </div>
                <table className=" table table-striped  ">
                    <thead >
                        <tr>
                            <th>#</th>
                            <th>Area</th>
                            <th>Collection type</th>
                            <th>Collection status</th>
                            <th>Collection date</th>
                            <th>Driver</th>
                            <th>Helper</th>
                        </tr>
                    </thead>
                    <tbody    >
                        {!isLoadingCollectionList && !isErrorCollectionList && collectionList?.entities && Object.values(collectionList?.entities).map((item, index) => (
                            <tr className="listData" key={index} onClick={() => (handleNavigate(`/b2g/collections/${item.assignment_id}`))}>
                                <td>{index + 1 * (page * 10 - 9)}</td>
                                <td>{item.area_name}{" " + item.tag}</td>
                                <td>{item.category}</td>
                                <td>{item.status}</td>
                                <td>{moment(item.collection_date).format('YYYY-MM-DD')}</td>
                                <td>{item.p_name}</td>
                                <td>{item.helper_name}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="daily_collection">
                {!isLoadingCollectionList && !isErrorCollectionList && collectionList?.entities && Object.values(collectionList?.entities).map((item, index) => (
                    <div key={index} className="Card_table_data " onClick={() => (handleNavigate(`/b2g/collections/${item.assignment_id}`))}>
                        <div div className="Card_data">
                            <span className="Card_srNo">#</span>
                            <span className="Card_data_list">{index + 1 * (page * 10 - 9)}</span>
                        </div>
                        <div className="Card">
                            <div className="Card_data">
                                <span className="Card_srNo">Area :-</span>
                                <span className="Card_data_list">{item.area_name}</span>
                            </div>
                            <div className="Card_data">
                                <span className="Card_srNo">Collection type :-</span>
                                <span className="Card_data_list">{item.category}</span>
                            </div>
                        </div>
                        <div className="Card">
                            <div className="Card_data">
                                <span className="Card_srNo">Collection :-</span>
                                <span className="Card_data_list">{item.status}</span>
                            </div>
                            <div className="Card_data">
                                <span className="Card_srNo">Collection :-</span>
                                <span className="Card_data_list">{moment(item.collection_date).format('YYYY-MM-DD')}</span>
                            </div>
                        </div>
                        <div className="Card">
                            <div className="Card_data">
                                <span className="Card_srNo">Driver :-</span>
                                <span className="Card_data_list">{item.p_name}</span>
                            </div>
                            <div className="Card_data">
                                <span className="Card_srNo">Helper :-</span>
                                <span className="Card_data_list">{moment(item.collection_date).format('YYYY-MM-DD')}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <Pagination page={page} totalPages={totalPages} setPage={setPage} />
        </div>
    )
}