import WardExcerpt from "./wardExcerpt";
import AddWard from "./addWard";
import SpinnerForPage from "../../components/spinner/SpinnerForPage";
import { useGetincQuery } from "./wardSlice";
import { useRef, useState } from "react";
import IncExcerpt from "./IncExcerpt";
import { useParams } from "react-router-dom";
import EditInc from "./EditInc";
import AddInc from "./addInc";

const Inc = () => {
    const { id } = useParams();
    const [editINC, setEditINC] = useState({})
    const refOpen = useRef();

    const {
        data: inc,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetincQuery(id);

    const editCommunity = (incId) => {
        refOpen.current.click()
        const data = inc?.entities[incId]
        setEditINC({ inc_id: data?.inc_id, inc_name: data?.inc_name, inc_leave: data?.inc_leave, inc_blocked: data?.inc_blocked, inc_ward_id: data?.inc_ward_id })
    }

    return (
        <>
            <div className="container-fluid mt-5">
                <div className="text-right">
                    <AddInc />
                </div>
                <div className="card card-dark mt-4">
                    <div className="table-border-style">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead className="search_button">
                                    <tr className=" text-left">
                                        <th scope="col">Sr. No.</th>
                                        <th scope="col">Inc Name</th>
                                        <th scope="col">Inc Number</th>
                                        <th scope="col">Leave</th>
                                        <th scope="col">Blocked</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        inc?.ids?.map((Id, index) => {
                                            return <IncExcerpt editCommunity={editCommunity} inc={inc?.entities[Id]} index={index} />
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <EditInc refOpen={refOpen} editINC={editINC} setEditINC={setEditINC} />
        </>
    )
}

export default Inc