import React, { useState, useRef } from "react";
import { useAddNewSupervisorMutation, useGetWardlistQuery } from "./supervisorSlice";

const AddSupervisorOffCanavas = () => {

    const {
        data: Wardlist
    } = useGetWardlistQuery('getWardlist');

    const [addNewSupervisor, { isLoading }] = useAddNewSupervisorMutation();
    const [addSupervisor, setAddSupervisor] = useState({ number: "", name: "", ward_id: "" });
    const refClose = useRef(null);

    const handleChange = (e) => setAddSupervisor({ ...addSupervisor, [e.target.name]: e.target.value });

    const handleClick = async (e) => {
        e.preventDefault();
        try {
            await addNewSupervisor({ name: addSupervisor.name, number: addSupervisor.number, ward_id: addSupervisor.ward_id }).unwrap()
            setAddSupervisor({ number: "", name: "", ward_id: '' })
            refClose.current.click()
        }
        catch (e) {
            console.error(e);
        }
    }


    return (
        <>
            <button type="button" className="Add_button bg-left-navabr  text-light  btn btn-secondary" data-bs-toggle="offcanvas" data-bs-target="#depOffCanvas" aria-controls="depOffCanvas"><i className="fa fa-plus" aria-hidden="true"></i> Add New Supervisor</button>

            <div className="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="1" id="depOffCanvas" aria-labelledby="depOffCanvas">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvas1Label">Add Supervisor</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ref={refClose}></button>
                </div>
                <div className="offcanvas-body">
                    <div>
                        <form className="row g-3">
                            <div className="col-md-12 text-left">
                                <label htmlFor="name" className="form-label font-weight-bold">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    value={addSupervisor.name}
                                    onChange={handleChange}
                                    placeholder=""
                                    autoComplete="off"
                                    required
                                />
                            </div>
                            <div className="col-md-12 text-left">
                                <label htmlFor="number" className="form-label font-weight-bold">
                                    Number
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="number"
                                    name="number"
                                    value={addSupervisor.number}
                                    onChange={handleChange}
                                    placeholder=""
                                    autoComplete="off"
                                    required
                                />
                            </div>
                            <div className="col-md-12 text-left">
                                <label htmlFor="ward_id" className="form-label font-weight-bold">
                                    Ward
                                </label>
                                <select name="ward_id" onChange={handleChange} className="form-control">
                                    <option>Ward Number</option>
                                    {Wardlist?.ids?.map((e) => {
                                        return <option key={e} id="ward_id"
                                            value={Wardlist?.entities[e].ward_id}
                                        >{Wardlist?.entities[e].ward_number}</option>
                                    })}
                                </select>
                            </div>

                            <div className="col-md-12">
                                <button type="submit" className="btn btn-ff9b44 btn-block btn-round"
                                    onClick={handleClick}
                                    disabled={!(addSupervisor.name && addSupervisor.number && addSupervisor.ward_id)}
                                >
                                    {isLoading ? <i className="fa fa-spinner fa-spin"></i> : "Submit"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddSupervisorOffCanavas;