import { useLocation } from "react-router-dom";
import { nanoid } from "nanoid";
import B2GBreadcrumb from "../../components/breadcrumb/B2GBreadcrumb";
import { useGetDailyReportQuery } from "./DailyReportSlice";
import { useState, useEffect } from "react";
import img from "../../images/satellite2.jpg"
import sampleImg from "../../images/giphy.webp"


const getImageFile = async (imageUrl) => {
    try {
        const res = await fetch(imageUrl);
        const blob = await res.blob();

        return new File([blob], "image", { type: blob.type });
    } catch (error) {
        console.log("Error fetching original image:", error);
    }
};

const getAIImage = async (imageFormData) => {
    try {
        imageFormData.append("model_type", false)
        const res = await fetch("https://imagerating.ecowrap.in/inference", {
            method: "POST",
            body: imageFormData,
        });

        const file = await res.blob();

        return file;
    } catch (error) {
        console.log("error in fetching ai image", error);
    }
}

const SAMPLE_IMAGE_URL = 'assets/iamges/giphy.webp';


/**
 * @Component
 */
const DailyReportDetail = () => {
    const iec = useLocation().state;
    const [rotationState, setRotationState] = useState({});
    const [rotatecount, setrotatecount] = useState(0)
    const [size, setSize] = useState(false);
    const [aiImageUrls, setAiImageUrls] = useState([]);
    const [loading, setLoading] = useState(true);

    const Zoomout_image = () => {
        if (size == true) {
            const allImages = document.querySelectorAll('.mix-empty-img');
            allImages.forEach((img) => {
                img.classList.remove('mix-empty-img-enlar');
                img.style.transform = 'none';
            });
            setSize(false)
        }
    }

    const imgZoomToggle = (e) => {
        const allImages = document.querySelectorAll('.mix-empty-img');
        const clickedElement = e.currentTarget;
        const isAlreadyEnlarged = clickedElement.classList.contains('mix-empty-img-enlar');
        setSize(true);
        if (!isAlreadyEnlarged) {
            clickedElement.classList.add('mix-empty-img-enlar');
            const rotation = rotationState[clickedElement.id] || 0;
            clickedElement.style.transform = `rotate(${rotation}deg) scale(2)`;
        } else {
            setRotationState({ [clickedElement.id]: 0 })
            setrotatecount(0);
        }
        Zoomout_image()
    };

    const rotateImage = (e, imgId) => {
        const image = document.getElementById(imgId);
        if (image && image.classList.contains('mix-empty-img-enlar')) {
            let currentRotation = 0
            currentRotation = rotationState[imgId] || 0;
            const newRotation = currentRotation + 90;

            setRotationState(prevState => ({
                ...prevState,
                [imgId]: newRotation
            }));

            if (rotatecount % 2 == 0) {
                image.style.transform = `rotate(${newRotation}deg) scale(2,4)`;
            } else {
                image.style.transform = `rotate(${newRotation}deg) scale(2)`;
            }

            setrotatecount(rotatecount + 1)
        }
    };

    const getAiCountedImageUrl = async (imageUrl) => {
        try {
            const data = new FormData();

            // Get original image.
            const imageFile = await getImageFile(imageUrl);
            data.append(imageFile.name, imageFile);

            // Get AI image.
            const aiImageBlob = await getAIImage(data);

            // convert blob to url.
            const aiImageUrl = URL.createObjectURL(aiImageBlob);

            return aiImageUrl
        } catch (err) {
            console.error("Error in handleSubmit:", err);
        }
    };

    useEffect(() => {
        if (iec?.images !== null) {
            (async () => {

                const imagesArr = iec?.images.split(",");

                for (let i = 0; i < imagesArr.length; i++) {
                    setAiImageUrls(prev => {
                        prev[i] = sampleImg;
                        return [...prev];
                    });
                }

                for (let i = 0; i < imagesArr.length; i++) {
                    const e = imagesArr[i];
                    const url = await getAiCountedImageUrl(`https://apis.ecowrap.in/${e}`);
                    setAiImageUrls(prev => {
                        prev[i] = url;
                        return [...prev];
                    });
                }
            })()
        }
    }, []);
    console.log("iec", iec);

    return (
        <>
            <div className="container-fluid mt-5 " style={{ height: '100vh', paddingBottom: '150px' }}>
                <div className="row">
                    <div className="col-md-6 text-left">
                        <h6 className="page-title">Daily Report Person Details</h6>
                        <ul className="breadcrumb">
                            <B2GBreadcrumb />
                        </ul>
                    </div>
                </div>
                <div onClick={Zoomout_image} className="card mb-3 rounded card-dark w-100 p-4" style={{ borderRadius: '20px' }}>
                    <h3 className="mb-3">Property Details</h3>
                    <div className="row">
                        <h6 className="col-md-4 mb-4">House Number : {iec?.house_number}</h6>
                        <h6 className="col-md-4 mb-4">Full Address : {iec?.full_address}</h6>
                        <h6 className="col-md-4 mb-4">Street : {iec?.street}</h6>
                        {/* {(iec?.vehicle_number == (null || '') && <h6 className="col-md-4 mb-4 ">Vehicle Number : NA </h6>) || <h6 className="col-md-4 mb-4">Vehicle Number : {iec?.user_identifiers}</h6>} */}
                        <h6 className="col-md-4 mb-4">Total Members : {iec?.total_members == -1 ? "N/A" : iec?.total_members}</h6>
                        <h6 className="col-md-4 mb-4">Date : {new Date(iec?.collected_at).toISOString().split('T')[0]}</h6>
                    </div>
                </div>
                <div className="card mt-3 rounded card-dark w-100 p-4" style={{ borderRadius: '20px' }}>

                    <table className="table table-borderless" >
                        <tr>
                            {aiImageUrls?.map((image, e) => {
                                return <td key={e} className="col-md-4 mb-4">
                                    <div className="d-flex justify-content-center flex-row" style={{ objectFit: 'contain' }}>
                                        <img onClick={imgZoomToggle} id={`docImg${image + 1}`} className="mix-empty-img" style={{ height: '200px', width: '200px' }} src={image} alt={image} />
                                        <button className="rotate-button btn-dark" onClick={(event) => rotateImage(event, `docImg${image + 1}`)} > Rotate Image </button>
                                    </div>
                                </td>
                            })}
                        </tr>
                        <tr>
                            {iec?.rating?.split(",").map((e) => {
                                return <td key={e} className="col-md-4 mb-4">
                                    <div className="d-flex justify-content-center flex-row" style={{ objectFit: 'contain' }}>
                                        Rating : {e}
                                    </div>
                                </td>
                            })}
                        </tr>
                        <tr>
                            {iec?.response?.split("}").map((e) => {
                                return <td key={e} className="col-md-4 mb-4 ">
                                    <div className="d-flex justify-content-center flex-row" style={{ objectFit: 'contain' }}>
                                        {e.split(/[{},"]/)}
                                    </div>
                                </td>
                            })}
                        </tr>
                    </table>
                </div>
            </div >

        </>
    );
};

export default DailyReportDetail;