import { useDeleteDriverMutation } from "./wardSlice";
import swal from "sweetalert";

const DriverWardExcerpt = ({ editDriverdata, driver, index }) => {
    const [DeletedriverData] = useDeleteDriverMutation();

    const deleteHandle = async (driver_id) => {
        const userConfirmed = await swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        });

        if (userConfirmed) {
            try {
                await DeletedriverData(driver_id).unwrap();
                swal("Done", "Data Deleted Successfully", "success");
            } catch (error) {
                swal("Error", "Failed to delete the data", "error");
            }
        } else {
            swal("Cancelled", "Your data is safe!", "info");
        }
    };
    return (
        <>
            <tr key={index} className="listData search_button text-left fw-normal">
                <td>{index + 1}</td>
                <td>{driver?.p_name}</td>
                <td>{driver?.p_number}</td>
                <td>{driver?.is_leave == 0 ? "No" : "yes"}</td>
                <td>{driver?.is_blocked == 0 ? "No" : "yes"}</td>
                <td>{driver?.p_deleted == 0 ? "No" : "yes"}</td>

                <td>
                    <div className="dropdown">
                        <button
                            className="btn dropdown-toggle p-2"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{ border: 'none' }}
                        >
                            <i className="fas fa-ellipsis-v" style={{ color: 'gray' }}></i>
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>
                                <div
                                    className="dropdown-item"
                                    onClick={() => editDriverdata(driver?.p_id)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Edit
                                </div>
                                <div
                                    className="dropdown-item"
                                    onClick={() => deleteHandle(driver?.p_id)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Delete
                                </div>
                            </li>
                        </ul>
                    </div>
                </td>
            </tr>
            <div key={index} className="Card_table_data">
                <div div className="Card_data">
                    {/* <span className="Card_srNo">Sr. No.</span> */}
                    <span className="Card_srNo">{index + 1}</span>
                </div>
                <div className="Card">
                    <div className="Card_data">
                        <span className="Card_srNo">Driver Name :-</span>
                        <span className="Card_data_list">{driver?.p_name}</span>
                    </div>
                    <div className="Card_data">
                        <span className="Card_srNo">Driver Number :-</span>
                        <span className="Card_data_list">{driver?.p_number}</span>
                    </div>
                </div>
                <div className="Card">
                    <div className="Card_data">
                        <span className="Card_srNo">Leave :-</span>
                        <span className="Card_data_list">{driver?.is_leave == 0 ? "No" : "yes"}</span>
                    </div>
                    <div className="Card_data">
                        <span className="Card_srNo">Blocked :-</span>
                        <span className="Card_data_list">{driver?.is_blocked == 0 ? "No" : "yes"}</span>
                    </div>
                </div>

                <div className="Card">
                    <div className="Card_data">
                        <span className="Card_srNo">Deleted :-</span>
                        <span className="Card_data_list">{driver?.p_deleted == 0 ? "No" : "yes"}</span>
                    </div>
                    <span className="Card_srNo">Action :-</span>
                    <span className="Card_data_list">
                        <div className="dropdown">
                            <button
                                className="btn dropdown-toggle p-2"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                style={{ border: 'none' }}
                            >
                                <i className="fas fa-ellipsis-v" style={{ color: 'gray' }}></i>
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <div
                                        className="dropdown-item"
                                        onClick={() => editDriverdata(driver?.p_id)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        Edit
                                    </div>
                                    <div
                                        className="dropdown-item"
                                        onClick={() => deleteHandle(driver?.p_id)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        Delete
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </span>
                </div>
            </div>
        </>
    );
};

export default DriverWardExcerpt;