import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

const GeofenceTable = ({ urlB2G, wardData }) => {
    const [geofences, setGeofences] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const authState = useSelector((state) => state.auth);
    const { wardId, wardNumber, wardCommunity } = wardData;

    useEffect(() => {
        if (!wardData.wardId) {
            navigate('/b2g/wards');
            return;
        }
    }, [wardData, navigate]);

    // Fetch geofences data
    useEffect(() => {
        if (wardId) {
            const fetchGeofences = async () => {
                setLoading(true);
                try {
                    const response = await fetch(`${urlB2G}/address/community/ward/geo-fance?ward_id=${wardId}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${authState.token}`,
                        },
                    });
                    const data = await response.json();
                    if (response.ok && data.status) {
                        setGeofences(data.data);
                    } else {
                        setError('No geofence data found.');
                    }
                } catch (error) {
                    setError('Error fetching geofence data.');
                } finally {
                    setLoading(false);
                }
            };

            fetchGeofences();
        }
    }, [urlB2G, wardId, authState.token]);

    const handleAddGeofence = () => {
        navigate('/b2g/addGeofence', { state: { wardId, wardNumber, wardCommunity } });
    };

    const handleEditGeofence = (geo_id) => {
        navigate('/b2g/editWardMap', { state: { geo_id, wardId } });
    };

    const handleDeleteGeofence = async (geo_id) => {
        try {
            const res = await fetch(`https://apis.ecowrap.in/b2g/eco-admin/address/community/ward/geo-fance/${geo_id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authState.token}`,
                },
            });
            const response = await res.json();
            if (response.status) {
                setGeofences((prevGeofences) =>
                    prevGeofences.filter((geofence) => geofence.geo_id !== geo_id)
                );
            } else {
                setError('Failed to delete geofence.');
            }
        } catch (e) {
            setError('Error deleting geofence.');
        }
    };

    if (loading) {
        return <div>Loading geofences...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    const handleDelete = (geofenceID) => {
        Swal.fire({
            title: "Are you sure?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteGeofence(geofenceID);
            }
        });
    }

    return (
        <div className="container-fluid mt-5">
            <div className="row d-flex justify-content-between">
                <div className="col-md-6 text-left">
                    <h5 className="page-title">
                        Ward {wardNumber} {wardCommunity} Geofence
                    </h5>
                </div>
                <div className="col-md-2 text-right">
                    <button className="btn btn-secondary" onClick={handleAddGeofence}>
                        Add
                    </button>
                </div>
            </div>
            <div className="card card-dark mt-4">
                <div className="table-responsive">
                    <table className="search_button table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Sr. No.</th>
                                <th scope="col">Area Name</th>
                                <th scope="col">Tag</th>
                                <th scope="col">Type</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {geofences.length > 0 ? (
                                geofences.map((geofence, index) => (
                                    <tr key={geofence.geo_id}>
                                        <td>{index + 1}</td>
                                        <td>{geofence.area_name}</td>
                                        <td>{geofence.tag}</td>
                                        <td>{geofence.type}</td>
                                        <td>
                                            <div className="dropdown">
                                                <button
                                                    className="btn dropdown-toggle"
                                                    type="button"
                                                    id="dropdownMenuButton"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    <i className="fas fa-ellipsis-v"></i>
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <li>
                                                        <div
                                                            className="dropdown-item"
                                                            onClick={() => handleEditGeofence(geofence.geo_id)}
                                                        >
                                                            Edit
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div
                                                            className="dropdown-item"
                                                            onClick={() => handleDelete(geofence.geo_id)}
                                                        >
                                                            Delete
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4">No geofences available.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                    {geofences.length > 0 ? (
                        geofences.map((geofence, index) => (
                            <div key={geofence.geo_id} className="Card_table_data">
                                <div div className="Card_data">
                                    {/* <span className="Card_srNo">Sr. No.</span> */}
                                    <span className="Card_srNo">{index + 1}</span>
                                </div>
                                <div className="Card">
                                    <div className="Card_data">
                                        <span className="Card_srNo">Area Name :-</span>
                                        <span className="Card_data_list">{geofence.area_name}</span>
                                    </div>
                                    <div className="Card_data">
                                        <span className="Card_srNo">Tag :-</span>
                                        <span className="Card_data_list">{geofence.tag}</span>
                                    </div>
                                </div>
                                <div className="Card">
                                    <div className="Card_data">
                                        <span className="Card_srNo">Type :-</span>
                                        <span className="Card_data_list">{geofence.type}</span>
                                    </div>

                                    <span className="Card_srNo">Actions :-</span>
                                    <div className="dropdown">
                                        <button
                                            className="btn dropdown-toggle"
                                            type="button"
                                            id="dropdownMenuButton"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <i className="fas fa-ellipsis-v"></i>
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <li>
                                                <div
                                                    className="dropdown-item"
                                                    onClick={() => handleEditGeofence(geofence.geo_id)}
                                                >
                                                    Edit
                                                </div>
                                            </li>
                                            <li>
                                                <div
                                                    className="dropdown-item"
                                                    onClick={() => handleDelete(geofence.geo_id)}
                                                >
                                                    Delete
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="4">No geofences available.</td>
                        </tr>
                    )}
                </div>
            </div>
        </div>
    );
};

export default GeofenceTable;
