import B2GBreadcrumb from "../../components/breadcrumb/B2GBreadcrumb";
import SpinnerForPage from "../../components/spinner/SpinnerForPage";
import { useEffect, useState } from "react";
import { useGetDailyReportQuery } from "./DailyReportSlice";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "../../utils/Pagination";
import moment from "moment";

const DailyReportList = () => {

    const location = useLocation().state;

    const navigate = useNavigate();
    const [searchDailyReport, setSearchDailyReport] = useState({})
    const [filterData, setFilterData] = useState({})
    const [page, setPage] = useState(() => {
        const savedPage = localStorage.getItem('dailyReportPage');
        return savedPage ? Number(savedPage) : 1;
    })
    useEffect(() => {
        localStorage.setItem('dailyReportPage', page);
    }, [page]);
    console.log(location, "sdknhjvh dates ");

    const date = moment(location).format("YYYY-MM-DD").toLocaleString("en-US", { timeZone: "Asia/Calcutta", hour12: true, hour: "numeric", minute: "numeric" })

    const {
        data: dailyreportlist,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetDailyReportQuery({ page, searchDailyReport, date })

    let totalPages = 1;
    let totalCollection = 0;

    if (dailyreportlist && dailyreportlist.entities) {
        totalPages = dailyreportlist.totalPage;
    }

    const changeSearchHandle = (e) => {
        e.preventDefault();
        setFilterData({ ...filterData, [e.target.name]: e.target.value })
    }
    const searchHandle = () => {
        setSearchDailyReport(filterData)
        setPage(1)
    }
    const resetHandle = () => {
        setSearchDailyReport({
            "start_date": "",
            "end_date": "",
            "prp_name": "",
            "is_collected": "",
            "is_segregate": "",
        });
        setFilterData({
            "start_date": "",
            "end_date": "",
            "prp_name": "",
            "is_collected": "",
            "is_segregate": "",
        });
        setPage(1)
    }

    return (
        <>
            {isLoading && <SpinnerForPage />}
            <div className="container-fluid mt-5">
                {/* <div className="row">
                    <div className="col-md-6 text-left">
                        <h3 className="page-title">Daily Report </h3>
                        <ul className="breadcrumb">
                            <B2GBreadcrumb />
                        </ul>
                    </div>
                </div> */}

                <div className="search_button mb-4 w-100 d-flex justify-content-center gap-1">

                    <input value={filterData.prp_name} className="form-select w-25" name="prp_name" type="text" onChange={changeSearchHandle} placeholder="Property Name" />
                    <select value={filterData.is_collected} className="form-select w-25" name="is_collected" onChange={changeSearchHandle}>
                        <option value=''>Select Collected</option>
                        <option value='1'>Yes</option>
                        <option value='0'>No</option>
                    </select>
                    <select value={filterData.is_segregate} className="form-select w-25" name="is_segregate" onChange={changeSearchHandle}>
                        <option value=''>Select Segregate</option>
                        <option value='1'>Yes</option>
                        <option value='0'>No</option>
                    </select>
                    <button className="btn btn-secondary bg-left-navabr " onClick={searchHandle} >Search</button>
                    <button className="btn btn-secondary bg-left-navabr " onClick={resetHandle} >Reset</button>
                </div>
                <div className="Card_table_data w-25">
                    <button type="button" className="Add_button bg-left-navabr w-100 text-light  btn btn-secondary" data-bs-toggle="offcanvas" data-bs-target="#depOffsearch" aria-controls="depOffsearch"> Search</button>
                    <div className="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="1" id="depOffsearch" aria-labelledby="depOffsearch">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="offcanvas1Label">Search</h5>
                            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ></button>
                        </div>
                        <input value={filterData.prp_name} className="mt-2 form-select" name="prp_name" type="text" onChange={changeSearchHandle} placeholder="Property Name" />
                        <select value={filterData.is_collected} className="mt-2 form-select" name="is_collected" onChange={changeSearchHandle}>
                            <option value=''>Select Collected</option>
                            <option value='1'>Yes</option>
                            <option value='0'>No</option>
                        </select>
                        <select value={filterData.is_segregate} className="mt-2 form-select" name="is_segregate" onChange={changeSearchHandle}>
                            <option value=''>Select Segregate</option>
                            <option value='1'>Yes</option>
                            <option value='0'>No</option>
                        </select>
                        <button className="btn mt-2 btn-secondary bg-left-navabr " onClick={searchHandle} >Search</button>
                        <button className="btn mt-2 btn-secondary bg-left-navabr " onClick={resetHandle} >Reset</button>
                    </div>
                </div>
                <div className="card card-dark">
                    <div className="table-border-style">
                        <div className="table-responsive">
                            <table className="  table table-striped">
                                <thead>
                                    <tr className="search_button text-left">
                                        <th scope="col">Sr. No.</th>
                                        <th scope="col">House Number</th>
                                        <th scope="col">Street</th>
                                        <th scope="col">Waste Collected</th>
                                        <th scope="col">Waste Category</th>
                                        <th scope="col">Segregated</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {dailyreportlist?.entities?.map((e, index) => {
                                        return (
                                            <>
                                                {/* <tr key={index} className="listData text-left fw-normal" onClick={() => { navigate(`/b2g/dailyreportdetail/${e.id}/${page}/${index}`, { state: e }) }}> */}
                                                <tr key={index} className="search_button listData text-left fw-normal" onClick={() => { navigate(`/b2g/dailyreportdetail`, { state: e }) }}>
                                                    <td>{index + 1 * (page * 10 - 9)}</td>
                                                    <td>{e.house_number}</td>
                                                    <td>{e.street}</td>
                                                    <td key={index}>{e.waste_provided == 1 ? "Yes" : "NO"}</td>
                                                    <td>{JSON.parse(e.waste_category).map((e1) => {
                                                        return <span>{`${e1} `}</span>
                                                    })}</td>
                                                    <td>{e.is_segregate == 1 ? "Yes" : "No"}</td>
                                                </tr>
                                                <div key={index} className="Card_table_data" onClick={() => { navigate(`/b2g/dailyreportdetail`, { state: e }) }}>
                                                    <div div className="Card_data">
                                                        <span className="Card_srNo">{index + 1 * (page * 10 - 9)}</span>
                                                    </div>
                                                    <div className="Card">
                                                        <div className="Card_data">
                                                            <span className="Card_srNo">House Number :-</span>
                                                            <span className="Card_data_list">{e.house_number}</span>
                                                        </div>
                                                        <div className="Card_data">
                                                            <span className="Card_srNo">Street :-</span>
                                                            <span className="Card_data_list">{e.street}</span>
                                                        </div>
                                                    </div>
                                                    <div className="Card">
                                                        <div className="Card_data">
                                                            <span className="Card_srNo">Waste Collected :-</span>
                                                            <span className="Card_data_list" key={index}>{e.waste_provided == 1 ? "Yes" : "NO"}</span>
                                                        </div>
                                                        <div className="Card_data">
                                                            <span className="Card_srNo">Waste Collected :-</span>
                                                            <span className="Card_data_list">{JSON.parse(e.waste_category).map((e1) => {
                                                                return <span>{`${e1} `}</span>
                                                            })}</span>
                                                        </div>
                                                    </div>
                                                    <div className="Card">
                                                        <div className="Card_data">
                                                            <span className="Card_srNo">Waste Collected :-</span>
                                                            <span className="Card_data_list">{e.is_segregate == 1 ? "Yes" : "No"}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
                <Pagination page={page} totalPages={totalPages} setPage={setPage} />
            </div>

        </>
    )
}

export default DailyReportList;